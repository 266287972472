import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import "./ChatWindow.css";

// SVG copy icon
const CopyIcon = () => (
  <span style={{ fontFamily: "Material Symbols Outlined", fontSize: "16px", color: "white" }}>
    content_copy
  </span>
);

// SVG check icon
const CheckIcon = () => (
  <span style={{ fontFamily: "Material Symbols Outlined", fontSize: "16px", color: "white" }}>
    check
  </span>
);


// SVG thumbs up icon (replaced with checkmark if rated)
const ThumbsUpIcon = ({ rated, onClick }) => (
  <span
    onClick={onClick}
    style={{
      fontFamily: "Material Symbols Outlined",
      fontSize: "16px",
      cursor: rated === null ? "pointer" : "default",
      color: "white",
      marginLeft: "8px",
    }}
  >
    {rated === 1 ? "done_all" : "thumb_up"}
  </span>
);

// SVG thumbs down icon (replaced with checkmark if rated)
const ThumbsDownIcon = ({ rated, onClick }) => (
  <span
    onClick={onClick}
    style={{
      fontFamily: "Material Symbols Outlined",
      fontSize: "16px",
      cursor: rated === null ? "pointer" : "default",
      color: "white",
      marginLeft: "8px",
    }}
  >
    {rated === -1 ? "done_all" : "thumb_down"}
  </span>
);

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const FeedbackForm = ({ onClose, isVisible, feedbackType, messageId }) => {
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formBackgroundColor = "#3b0764";
  const formTextColor = "#e9d8fd";
  const buttonBorderColor = "#d53f8c";
  const hoverBackgroundColorStart = "#674088";
  const hoverBackgroundColorEnd = "#25164a";
  const hoverBorderColor = "#25164a";
  const selectedBackgroundColor = "#d53f8c";
  const selectedTextColor = "#ffffff";
  const selectedBoxShadow = "0 0 10px rgba(255, 105, 180, 0.8)";

  const reasons =
    feedbackType === "positive"
      ? ["Makes me feel better", "Helps with my problem", "It's something new", "It's like she knows me"]
      : ["Not Relevant to the Topic", "Contains Inaccurate Information", "Lacks Sufficient Detail", "Not personalized enough"];

  const handleReasonToggle = (reason) => {
    setSelectedReasons((prevSelectedReasons) =>
      prevSelectedReasons.includes(reason)
        ? prevSelectedReasons.filter((r) => r !== reason)
        : [...prevSelectedReasons, reason]
    );
  };

  const handleSubmit = async () => {
    const rating = feedbackType === "positive" ? 1 : -1;

    try {
      const response = await fetch(`${API_BASE_URL}/chat/messages/rate`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message_id: messageId,
          rating: rating,
          reasons: selectedReasons,
        }),
      });

      const data = await response.json();
      console.log("Rating submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting rating:", error);
    }

    onClose(rating);
  };

  return (
    <div
      className={`feedback-form ${isVisible ? "visible" : ""}`}
      style={{
        backgroundColor: formBackgroundColor,
        color: formTextColor,
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 8px 16px rgba(0,0,0,0.25)",
        marginTop: "10px",
        marginBottom: "20px",
        width: "85%",
        margin: "10px auto 20px",
        maxWidth: "1000px",
        overflow: "hidden",
        transition: "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out",
        maxHeight: isVisible ? "500px" : "0",
        opacity: isVisible ? 1 : 0,
      }}
    >
      <p style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>Tell us more:</p>
      <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap" }}>
        {reasons.map((reason) => (
          <button
            key={reason}
            style={{
              backgroundColor: selectedReasons.includes(reason)
                ? selectedBackgroundColor
                : "transparent",
              padding: "10px",
              border: `2px solid ${selectedReasons.includes(reason) ? selectedBackgroundColor : buttonBorderColor}`,
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "16px",
              color: selectedReasons.includes(reason) ? selectedTextColor : formTextColor,
              flex: "1 1 22%",
              minWidth: "120px",
              transition: "background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s",
              boxSizing: "border-box",
              boxShadow: selectedReasons.includes(reason) ? selectedBoxShadow : "none",
              transform: selectedReasons.includes(reason) ? "scale(1.05)" : "scale(1)",
            }}
            onClick={() => handleReasonToggle(reason)}
            onMouseEnter={(e) => {
              if (isLargeScreen && !selectedReasons.includes(reason)) {
                e.target.style.background = `linear-gradient(90deg, ${hoverBackgroundColorStart}, ${hoverBackgroundColorEnd})`;
                e.target.style.borderColor = hoverBorderColor;
              }
            }}
            onMouseLeave={(e) => {
              if (isLargeScreen && !selectedReasons.includes(reason)) {
                e.target.style.background = "transparent";
                e.target.style.color = formTextColor;
                e.target.style.borderColor = buttonBorderColor;
              }
            }}
          >
            {reason}
          </button>
        ))}
      </div>
      <button
        style={{
          marginTop: "12px",
          color: formTextColor,
          background: "none",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
        }}
        onClick={handleSubmit}
      >
        Submit
      </button>
      <button
        style={{
          marginTop: "12px",
          color: formTextColor,
          background: "none",
          border: "none",
          cursor: "pointer",
          float: "right",
          fontSize: "16px",
        }}
        onClick={() => onClose(null)}
      >
        Close
      </button>
    </div>
  );
};

const ChatMessage = ({ message }) => {
  const [iconType, setIconType] = useState("copy");
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [iconsVisible, setIconsVisible] = useState(false);

  // Initialize feedback states based on the message's rating
  const [positiveFeedbackGiven, setPositiveFeedbackGiven] = useState(
    message.rating && message.rating.rating === 1
  );
  const [negativeFeedbackGiven, setNegativeFeedbackGiven] = useState(
    message.rating && message.rating.rating === -1
  );

  // Initialize the feedbackType state
  const [feedbackType, setFeedbackType] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIconsVisible(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    if (!showFeedbackForm && window.innerWidth >= 768) {
      setIconsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) {
      setIconsVisible(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(message.content)
      .then(() => {
        setIconType("check");
        setTimeout(() => {
          setIconType("copy");
          if (window.innerWidth >= 768) {
            setIconsVisible(false);
          }
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleThumbsDownClick = () => {
    setFeedbackType("negative");
    setShowFeedbackForm(true);
    setIconsVisible(false);
  };

  const handleThumbsUpClick = () => {
    setFeedbackType("positive");
    setShowFeedbackForm(true);
    setIconsVisible(false);
  };

  const handleCloseFeedbackForm = (rating) => {
    setShowFeedbackForm(false);
    if (rating === 1) {
      setPositiveFeedbackGiven(true);
    } else if (rating === -1) {
      setNegativeFeedbackGiven(true);
    }
    if (window.innerWidth <= 768) {
      setIconsVisible(true);
    }
  };

  // Disable both buttons if feedback has been given
  const feedbackGiven = positiveFeedbackGiven || negativeFeedbackGiven;

  return (
    <div
      className={`message flex flex-col ${message.role === "user" ? "from-me items-end" : "from-them items-start"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <div
        className={`relative max-w-[85%] w-auto rounded-3xl p-6 mb-2 text-sm shadow-lg ${
          message.role === "user" ? "bg-blue-100" : "bg-purple-200 text-purple-900"
        }`}
      >
        <ReactMarkdown>{message.content}</ReactMarkdown>
        {message.source === "sms" && (
          <div style={{ fontSize: "12px", color: "#777", marginTop: "5px", textAlign: "right" }}>Sent on SMS</div>
        )}
      </div>
      <div className="flex items-center icon-container" style={{ opacity: iconsVisible ? 1 : 0, transition: "opacity 0.3s ease-in-out" }}>
        <button onClick={copyToClipboard} className="text-xs bg-transparent ml-4 rounded hover:bg-transparent">
          {iconType === "copy" ? <CopyIcon /> : <CheckIcon />}
        </button>
        {message.role !== "user" && (
          <>
            <ThumbsUpIcon rated={positiveFeedbackGiven ? 1 : feedbackGiven ? null : 0} onClick={feedbackGiven ? null : handleThumbsUpClick} />
            <ThumbsDownIcon rated={negativeFeedbackGiven ? -1 : feedbackGiven ? null : 0} onClick={feedbackGiven ? null : handleThumbsDownClick} />
          </>
        )}
      </div>
      {showFeedbackForm && (
        <FeedbackForm
          onClose={handleCloseFeedbackForm}
          isVisible={showFeedbackForm}
          feedbackType={feedbackType}
          messageId={message.id}
        />
      )}
    </div>
  );
};

// Main ChatWindow component
const ChatWindow = ({ messages, isTyping }) => {
  return (
    <div className="flex flex-col flex-grow mb-6">
      {messages.map((message, index) => (
        <ChatMessage key={message.id} message={message} />
      ))}
      {isTyping && <div className="typing-indicator">...</div>}
    </div>
  );
};

export default ChatWindow;
