import React, { useState, useEffect } from "react";

function PhoneNumberVerificationModal({ isOpen, onClose, onVerify, errorMessage }) {
  const [verificationCode, setVerificationCode] = useState("");
  const [hoverResend, setHoverResend] = useState(false);

  const sendCode = async () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";
    try {
      const response = await fetch(`${API_BASE_URL}/auth/send_sms_code`, {
        method: 'GET',
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        console.log('SMS code sent successfully:', data);
      } else {
        throw new Error('Failed to send SMS code');
      }
    } catch (error) {
      console.error('Error sending SMS code:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      sendCode();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleVerificationCodeChange = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    if (code.length <= 6) {
      setVerificationCode(code);
      if (code.length === 6) {
        onVerify(code); // Call the onVerify function passed from the parent component
      }
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>Verify Your Phone Number</h2>
          <button style={styles.closeButton} onClick={onClose}>
            <XIcon />
          </button>
        </div>
        <div style={styles.body}>
          <p>Enter the 6-digit code sent to your phone:</p>
          <input
            type="text"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            placeholder="Enter verification code"
            style={styles.input}
            maxLength={6}
          />
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          <button
            style={{
              ...styles.button,
              backgroundColor: hoverResend ? "#b0bec5" : "transparent",
            }}
            onMouseEnter={() => setHoverResend(true)}
            onMouseLeave={() => setHoverResend(false)}
            onClick={sendCode}
          >
            Resend Code
          </button>
        </div>
      </div>
    </div>
  );
}

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      style={{ width: "24px", height: "24px" }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    background: "linear-gradient(to bottom, #24154A, #9D62BB)",
    borderRadius: "1.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    maxWidth: "400px",
    width: "90%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  title: {
    margin: 0,
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  closeButton: {
    position: "absolute",
    top: "-18px",
    right: "-15px",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "white",
  },
  body: {
    marginTop: "20px",
    color: "white",
    textAlign: "left",
  },
  input: {
    marginTop: "30px",
    width: "100%",
    padding: "10px",
    borderRadius: "1rem",
    color: "black",
    backgroundColor: "white",
    border: "1px solid #ccc",
    marginBottom: "20px",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    padding: "10px",
    borderRadius: "1rem",
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
};

export default PhoneNumberVerificationModal;
