import React, { useState, useEffect } from 'react';

export default function EmailMe({ closeModal }) {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  const handleSend = async () => {
    if (!isEmailValid) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/chat/transcript`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to send message");
      }

      setEmailSent(true);
    } catch (error) {
      console.error('Error:', error);
      setEmailError(error.message || 'An unexpected error occurred');
    }
  };

  if (emailSent) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
        <div className="rounded-lg shadow-xl w-full max-w-sm my-auto mx-4 p-8 bg-gradient-to-br from-purple-700 via-purple-800 to-purple-900" style={{ backgroundImage: `linear-gradient(to bottom, #24154A, #9D62BB)` }}>
          <div className="text-left text-white">
            <h2 style={{ marginBottom: '16px' }} className="text-xl font-bold">Sent!</h2>
            <p style={{ marginBottom: '16px' }}>A copy of your conversation was sent to {email}</p>
            <p style={{ marginBottom: '150px' }}>Thanks again and hope to see you soon! </p>
            <button className="mt-4 w-full bg-pink-500 text-white font-bold py-2 px-4 rounded hover:bg-pink-600" onClick={closeModal}>
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
      <div className="rounded-lg shadow-xl w-full max-w-sm my-auto mx-4 p-8 bg-gradient-to-br from-purple-700 via-purple-800 to-purple-900" style={{ backgroundImage: `linear-gradient(to bottom, #24154A, #9D62BB)` }}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-white">Send a copy</h2>
          <button className="rounded-full p-2 inline-flex items-center justify-center text-white focus:outline-none" onClick={closeModal}>
            <XIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="mt-4 text-white">
          <p>Thank you for chatting with us. We hope your concerns were addressed.</p>
          <p className="mt-2">Enter your email below to receive a copy of your conversation.</p>
          <div className="mt-10">
            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
            <input id="email" type="email" placeholder="example@email.com"
              className="block w-full px-4 py-3 bg-white bg-opacity-50 border-2 border-pink-500 rounded-md shadow-sm focus:outline-none focus:border-pink-600"
              value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          {emailError && <p className="text-red-500">{emailError}</p>}
          <div className="mt-6">
            <label htmlFor="terms" className="text-sm text-white">
              By emailing a copy of this conversation, you agree to our
              <a href="https://www.behumane.ai/privacy-policy" className="white-link hover:text-blue-400 underline"  target="_blank" rel="noreferrer noopener"> Privacy Policy</a> for the use of your email address.
            </label>
            <br />
            <br />
            <button className="w-full font-bold py-2 px-4 rounded"
              style={{
                backgroundColor: isEmailValid ? '#f472b6' : '#cbd5e1',
                color: isEmailValid ? 'white' : '#374151',
              }}
              onClick={handleSend} disabled={!isEmailValid}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function XIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
}
