import React, { useState } from "react";

function SignInNudgeModal({ isOpen, onClose }) {
  const [hoverSignIn, setHoverSignIn] = useState(false);
  const [hoverStay, setHoverStay] = useState(false);

  if (!isOpen) {
    return null;
  }

  const handleSignInClick = () => {
    onClose();
    window.location.href = "/login";
  };

  const handleStayUnauthenticatedClick = () => {
    onClose();
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>Don't have an account yet?</h2>
          <button style={styles.closeButton} onClick={onClose}>
            <XIcon />
          </button>
        </div>
        <div style={styles.body}>
          <br />
          <p>
            Sign up to enjoy personalized advice and easy access to your chat
            history.
          </p>
          <br />
          <br />
          <button
            style={{
              ...styles.signInButton,
              backgroundColor: hoverSignIn ? "#e0567d" : "#f472b6",
            }}
            onMouseEnter={() => setHoverSignIn(true)}
            onMouseLeave={() => setHoverSignIn(false)}
            onClick={handleSignInClick}
          >
            Yes! Sign in
          </button>
          <button
            style={{
              ...styles.stayButton,
              backgroundColor: hoverStay ? "#b0bec5" : "transparent",
            }}
            onMouseEnter={() => setHoverStay(true)}
            onMouseLeave={() => setHoverStay(false)}
            onClick={handleStayUnauthenticatedClick}
          >
            No, stay unauthenticated
          </button>
        </div>
      </div>
    </div>
  );
}

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    background: "linear-gradient(to bottom, #24154A, #9D62BB)",
    borderRadius: "1.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    maxWidth: "400px",
    width: "90%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    margin: 0,
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  closeButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "white",
  },
  body: {
    marginTop: "20px",
    color: "white",
    textAlign: "Left",
  },
  signInButton: {
    marginTop: "20px",
    width: "100%",
    padding: "10px",
    borderRadius: "1rem",
    backgroundColor: "#f472b6",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",

    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
  stayButton: {
    marginTop: "10px",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",

    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
};

export default SignInNudgeModal;
