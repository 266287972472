const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

// Retrieves the user's current timezone as a string
const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// Reports the user's timezone to the server and updates user profile
const reportTimezone = async () => {
  const timezone = getTimezone();  // Timezone is retrieved as a string
  console.log(`User's timezone: ${timezone}`);

  const body = JSON.stringify({ timezone });

  try {
    const response = await fetch(`${API_BASE_URL}/user/timezone`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });

    const responseData = await response.json();

    if (response.ok) {
      console.log("Timezone updated successfully. Updated profile:", responseData);
    } else {
      console.error("Failed to update timezone. Server responded with:", responseData);
      throw new Error(responseData.detail.map(error => error.msg).join(", ") || "Failed to update timezone");
    }
  } catch (error) {
    console.error("Error updating timezone:", error);
  }
};

export { getTimezone, reportTimezone };
