import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import SignInNudgeModal from "./nudge";
import SignOutModal from "./SignOut";
import Mascot from "./Mascot";
import ChatWindow from "./ChatWindow";
import InputArea from "./InputArea";
import EmailMe from "../services/EmailMe";
import UserService from "../services/UserService";
import { useMessages } from "../hooks";
import "./ChatInterface.css";
import suggestions from "./suggestion.json";
import GetPhoneNumberModal from "./getPhoneNumber";
import { reportTimezone } from "../services/localtime";
import PhoneNumberVerificationModal from "./verifyPhoneNumber";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function selectDiversePrompts(questions, count) {
  shuffleArray(questions);
  const prompts = [];
  const topicsSeen = new Set();
  for (const question of questions) {
    if (prompts.length >= count) break;
    if (!topicsSeen.has(question.tag)) {
      topicsSeen.add(question.tag);
      prompts.push({ question: question.question, tag: question.tag });
    }
  }
  return prompts;
}

const ChatInterface = () => {
  const [isPremium, setIsPremium] = useState("");

  const [inputValue, setInputValue] = useState("");
  const { messages, sendMessage, isTyping } = useMessages();
  const [showEmailMe, setShowEmailMe] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [showSignInNudgeModal, setShowSignInNudgeModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [suggestionPrompts, setSuggestionPrompts] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const initialWidthRef = useRef(window.innerWidth);
  const messageCountRef = useRef(0);
  const chatWindowRef = useRef(null);
  const activityTimeoutRef = useRef(null);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Add state for error message

  const closePhoneNumberModal = () => {
    setShowPhoneNumberModal(false);
    messageCountRef.current += 1;
  };

  const handleVerify = async (code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/verify_sms_code`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error("Failed to verify code");
      }

      const data = await response.json();

      if (data.verified) {
        console.log("Verification successful:", data);
        setPhoneNumberVerified(true);
        setShowVerificationModal(false);
        setShowPhoneNumberModal(false);
        setErrorMessage(""); // Clear any existing error message on success
      } else {
        console.log("Verification failed:", data);
        setErrorMessage(
          "Invalid or expired code. Try again or request for a new one."
        ); // Set error message on failure
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setErrorMessage("An error occurred. Please try again."); // Set error message on error
    }
  };

  const closeVerifyNumberModal = () => {
    setShowVerificationModal(false);
    setShowPhoneNumberModal(false);
    messageCountRef.current += 1;
  };

  useEffect(() => {
    reportTimezone(); //this is reporting the timezone, connection with the backend is needed.
  }, []);

  useEffect(() => {
    const checkPhoneNumberStatus = async () => {
      if (isSignedIn) {
        try {
          const data = await UserService.getProfile();

          const phoneExists = data.phone_number !== null;
          const verified = data.phone_number_verified;

          setPhoneNumberExists(phoneExists);
          setPhoneNumberVerified(verified);

          if (phoneExists && !verified) {
            setShowVerificationModal(true);
          }
        } catch (error) {
          console.error("Error fetching phone number status:", error);
          setPhoneNumberExists(false);
          setPhoneNumberVerified(false);
        }
      }
    };

    checkPhoneNumberStatus();
  }, [
    isSignedIn,
    setPhoneNumberExists,
    setPhoneNumberVerified,
    setShowVerificationModal,
  ]);

  const resetActivityTimeout = () => {
    clearTimeout(activityTimeoutRef.current);
    activityTimeoutRef.current = setTimeout(() => {
      setShowSuggestions(true);
      console.log("User has been inactive for 1 minute, showing suggestions."); //needs logic which can show the inferred prompts instead of the normal ones.
    }, 60000);
  };

  useEffect(() => {
    window.addEventListener("keydown", resetActivityTimeout);
    window.addEventListener("mousemove", resetActivityTimeout);

    return () => {
      window.removeEventListener("keydown", resetActivityTimeout);
      window.removeEventListener("mousemove", resetActivityTimeout);
      clearTimeout(activityTimeoutRef.current);
    };
  }, []);

  const chatContainerStyle = {
    height: showSuggestions ? "calc(100% - 355px)" : "calc(100% - 255px)",
  };

  const handleResize = useCallback(() => {
    const currentWidth = window.innerWidth;
    const threshold = 50;
    if (Math.abs(initialWidthRef.current - currentWidth) > threshold) {
      setWindowWidth(currentWidth);
      initialWidthRef.current = currentWidth;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    resetActivityTimeout();
  }, []);

  useEffect(() => {
    const promptCount = windowWidth < 768 ? 2 : 3;
    setSuggestionPrompts(
      selectDiversePrompts(suggestions.questions, promptCount)
    );
  }, [windowWidth]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    const fetchSignInStatus = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/status`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setIsSignedIn(data.authenticated);
      if (data.authenticated) {
        setUserEmail(data.email);
        reportTimezone();
        const storedPhone = localStorage.getItem("phone");
        if (storedPhone) {
          try {
            console.log(storedPhone);
            const typeOfPhone = typeof storedPhone;
            console.log(typeOfPhone);
            const updatedProfile = await UserService.updatePhoneNumber(
              storedPhone
            );
            if (updatedProfile) {
              console.log("Phone number updated successfully:", updatedProfile);
              localStorage.removeItem("phone");
            } else {
              console.error("Failed to update phone number");
            }
          } catch (error) {
            console.error("Error updating phone number:", error);
          }
        }
      }
    };

    fetchSignInStatus();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        let profile = await UserService.getProfile();
        console.log(profile);

        // Assuming the profile has a field like `isPremiumMember` to indicate premium status
        if (profile.is_premium) {
          setIsPremium(true);
        } else {
          setIsPremium(false);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const chatElement = chatWindowRef.current;
    if (chatElement) {
      setTimeout(() => {
        chatElement.scrollTop = chatElement.scrollHeight;
      }, 100);
    }
  }, [messages]);

  useEffect(() => {
    console.log(`Message count is currently: ${messageCountRef.current}`);
    if (
      messageCountRef.current === 10 &&
      isSignedIn &&
      !phoneNumberExists &&
      !showPhoneNumberModal
    ) {
      console.log("Showing phone number modal");
      setShowPhoneNumberModal(true);
    }
  }, [messages, isSignedIn, phoneNumberExists, showPhoneNumberModal]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    await sendMessage(inputValue.trim());
    setInputValue("");
    messageCountRef.current += 1;
    setShowSuggestions(false);

    console.log(`Updated message count: ${messageCountRef.current}`);

    if (messageCountRef.current === 7 && !isSignedIn) {
      setShowSignInNudgeModal(true);
    }
  };

  const handleEmailButtonClick = () => {
    setShowEmailMe(true);
  };

  const handleSignOut = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/logout`, {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        setIsSignedIn(false);
        setShowSignOutModal(false);
        window.location.reload();
      } else {
        throw new Error("Failed to sign out");
      }
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="chat-interface flex flex-col items-center w-full ">
      <div className="chat-content w-full md:w-3/4 mx-auto flex flex-col">
        <div style={styles.fixedContainer}>
        <div className="flex justify-between items-center w-full md:w-3/4 mx-auto relative">
  <a
    href="https://behumane.ai"
    target="blank"
    className="text-sm text-white hover:text-gray-300 p-4"
  >
    behumane.ai
  </a>

  {isPremium && (
    <div className="absolute left-1/2 transform -translate-x-1/2 text-white">
      Premium
    </div>
  )}

  {isSignedIn ? (
    <div className="flex flex-col items-end">
      <button
        onClick={() => setShowSignOutModal(true)}
        className="text-sm text-white hover:text-gray-300 p-2"
      >
        Sign Out
      </button>
      <p className="text-sm text-white px-2">
        You are signed in as {userEmail}
      </p>
    </div>
  ) : (
    <Link
      to="/login"
      className="text-sm text-white hover:text-gray-300 px-4"
    >
      Sign in
    </Link>
  )}
</div>

          <div className="rhea-heading mb-6 md:w-3/4 mx-auto flex justify-between items-center relative">
            <div>
              <h1 className="t1 text-4xl font-bold text-white mb-2 w-auto max-w-xs sm:max-w-sm md:max-w-md">
                R.H.E.A
              </h1>
              <p className="t2 text-xs lg:text-sm text-white w-auto max-w-xs sm:max-w-sm md:max-w-md">
                Guiding You Through the Challenges of Digital Age Parenting
              </p>
            </div>
            <Mascot
              className="mascot"
              style={{
                zIndex: 1,
                top: "-13px",
                right: "0px",
                position: "absolute",
              }}
            />
          </div>
        </div>
        <div
          className="flex-grow relative flex flex-col rounded-3xl shadow-lg border-2 fading-gradient fix-window"
          style={chatContainerStyle}
        >
          <div
            ref={chatWindowRef}
            className="chat-window flex flex-col flex-grow px-6 pt-6 bg-transparent overflow-y-auto"
            style={styles.chatWindow}
          >
            <ChatWindow messages={messages ?? []} isTyping={isTyping} />
          </div>
        </div>
      </div>

      <div className="input-area-container fixed bottom-12 mb-2 w-full px-15 md:w-3/4">
        {showSuggestions && (
          <div className="suggestion-prompts">
            {suggestionPrompts.map((prompt, index) => (
              <button
                key={index}
                onClick={() => {
                  sendMessage(prompt.question);
                  setShowSuggestions(false);
                }}
                className="suggestion-button"
              >
                <div className="tag">{prompt.tag}</div>
                <div className="question">{prompt.question}</div>
              </button>
            ))}
          </div>
        )}
        <InputArea
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onFormSubmit={handleFormSubmit}
        />
      </div>

      <div className="sticky-footer bg-gradient-to-b from-[#25164a] to-[#674088] p-2 w-full">
        <div className="input-box-container mx-auto w-full md:w-3/4">
          <div className="flex justify-between items-center text-white text-sm">
            <a
              href="mailto:support@behumane.co"
              className="hover:text-gray-300"
            >
              Need help?
            </a>
            <button
              onClick={handleEmailButtonClick}
              className="cursor-pointer hover:text-gray-300"
            >
              Email this conversation to me
            </button>
            {showEmailMe && (
              <EmailMe closeModal={() => setShowEmailMe(false)} />
            )}
          </div>
          <footer className="w-full text-center mt-auto">
            <p className="text-white text-sm opacity-75">
              © {new Date().getFullYear()} Be Humane, co.
            </p>
          </footer>
        </div>
      </div>
      <GetPhoneNumberModal
        isOpen={showPhoneNumberModal}
        onClose={() => closePhoneNumberModal()}
      />

      <SignOutModal
        isOpen={showSignOutModal}
        onClose={() => setShowSignOutModal(false)}
        onConfirm={handleSignOut}
      />
      <SignInNudgeModal
        isOpen={showSignInNudgeModal}
        onClose={() => setShowSignInNudgeModal(false)}
      />
      <PhoneNumberVerificationModal
        isOpen={
          showVerificationModal && phoneNumberExists && !phoneNumberVerified
        }
        onClose={() => closeVerifyNumberModal()}
        onVerify={handleVerify}
        errorMessage={errorMessage} // Pass the errorMessage to the modal
      />
    </div>
  );
};

export default ChatInterface;

const styles = {
  fixedContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 10,
  },
  chatWindow: {
    flexGrow: 1,
    flexDirection: "column",
    overflowY: "auto",
    minHeight: "300px",
  },
};
