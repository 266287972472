import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Mascot = ({ className, style }) => {
  const mascotRef = useRef(); // Ref for the div where the mascot will be rendered

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(20, mascotRef.current.clientWidth / mascotRef.current.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true  });
    renderer.setSize(mascotRef.current.clientWidth, mascotRef.current.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    mascotRef.current.appendChild(renderer.domElement);
    const mascotCurrent = mascotRef.current; // Copy the ref value to a variable inside the effect

    // Ambient light
    const ambientLight = new THREE.AmbientLight(0xF6E7D2, 1.5); // soft white light
    scene.add(ambientLight);

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1); // Position the light source
    scene.add(directionalLight);

    // Animation Mixer initialization
    let mixer;
    let clock = new THREE.Clock(); // Clock to track the delta time for animation updates

    const loader = new GLTFLoader();
    loader.load(
      './Rhea_full.glb',
      (gltf) => {
        scene.add(gltf.scene);
        camera.position.set(-0.5, 3.25, 9);

        // Check if there are animations
        if (gltf.animations && gltf.animations.length) {
          mixer = new THREE.AnimationMixer(gltf.scene);
          gltf.animations.forEach((clip, index) => {
            const action = mixer.clipAction(clip);
            action.play();
          });
        }

        animate();
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    const animate = () => {
      requestAnimationFrame(animate);
      const delta = clock.getDelta(); // Get the delta time

      if (mixer) { // Ensure mixer is defined
        mixer.update(delta); // Update the animation mixer
      }
      
      renderer.render(scene, camera);
    };

    return () => {
      if (mixer) mixer.stopAllAction(); // Stop all animations
      scene.clear(); // Clear the scene
      renderer.dispose(); // Dispose of the renderer
      if (mascotCurrent) mascotCurrent.removeChild(renderer.domElement); // Remove the canvas from the DOM using the variable
    };
  }, []);

  return <div ref={mascotRef} className={className} style={{ ...style, width: '200px', height: '100px' }} />;
};

export default Mascot;
