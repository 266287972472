import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ChatInterface from './components/ChatInterface.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/SignIn.js';
import LinkCode from './components/code.js';
function App() {
  useEffect(() => {
    // Ensuring GA is only initialized and events are only sent in production
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-R1F0GQ8NSL");
      // Tracking the 'website_accessed' event
      ReactGA.event({
        category: "Engagement",
        action: "website_accessed",
        label: "Landing Page"
      });
    }
  }, []);
  return (
    <div className="App flex flex-col justify-center"
         style={{ 
           background: '#25164a'
         }}>
		<Router>
			<div className='App'>
      <Routes>
        <Route path="/" element={<ChatInterface />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/code" element={<LinkCode />} />
      </Routes>
			</div>
		</Router>
    </div>
  );
}

export default App;
