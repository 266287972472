import React, { useState } from "react";
import PhoneNumberVerificationModal from "./verifyPhoneNumber";
import UserService from "../services/UserService";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

function GetPhoneNumberModal({ isOpen, onClose }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [hoverMaybeLater, setHoverMaybeLater] = useState(false);
  const [hoverNever, setHoverNever] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDuplicatePhone, setErrorDuplicatePhone] = useState(""); 

  const handleVerify = async (code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/verify_sms_code`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify code');
      }

      const data = await response.json();

      if (data.verified) {
        console.log('Verification successful:', data);
        handleModalClose();
      } else {
        setErrorMessage("Invalid or expired code. Try again or request for a new one."); 
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setErrorMessage("An error occurred. Please try again."); 
    }
  };

  if (!isOpen) {
    return null;
  }

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value.replace(/\D/g, '').slice(0, 10)); // Clean and limit input
  };

  const handleSubmitClick = async () => {
    if (phoneNumber.length === 10) {
      try {
        const updatedProfile = await UserService.updatePhoneNumber(phoneNumber);
        if (updatedProfile.response.toLowerCase() === "ok") {
          console.log('Phone number updated successfully:', updatedProfile);
          setVerificationOpen(true);
        } else {
          console.error(updatedProfile.error);
          setErrorDuplicatePhone(updatedProfile.error);
        }
      } catch (error) {
        console.error('Error updating phone number:', error);
        setErrorDuplicatePhone('Error updating phone number:', error);

      }
    } else {
      console.error('Phone number must be 10 digits');
      setErrorDuplicatePhone('Phone number must be 10 digits');
    }
  };

  const handleModalClose = () => {
    setVerificationOpen(false);
    setErrorMessage(""); 
    onClose();
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>Add your phone number</h2>
          <button style={styles.closeButton} onClick={onClose}>
            <XIcon />
          </button>
        </div>
        <div style={styles.body}>
          <p>Get parental tips and follow-up insights all via SMS:</p>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: "15px" }}>
            <span style={styles.prefix}>+1</span>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter your phone number"
              style={styles.input}
            />
          </div>
          {errorDuplicatePhone && <p className="text-red-500 text-sm mb-2">{errorDuplicatePhone}</p>}
          <p className="mt-5 mb-3 text-xs lg:text-xs text-gray-400">
            By providing your phone number, you are opting-in to receive helpful messages from Be Humane. You can always opt-out via text or from your Account Settings.
          </p>
          <button
            disabled={phoneNumber.length !== 10}
            style={styles.submitButton}
            onClick={handleSubmitClick}
          >
            Submit
          </button>
          <button
            style={{
              ...styles.maybeButton,
              backgroundColor: hoverMaybeLater ? "#b0bec5" : "transparent",
            }}
            onMouseEnter={() => setHoverMaybeLater(true)}
            onMouseLeave={() => setHoverMaybeLater(false)}
            onClick={onClose}
          >
            Maybe Later
          </button>
          <button
            style={{
              ...styles.neverButton,
              backgroundColor: hoverNever ? "#b0bec5" : "transparent",
            }}
            onMouseEnter={() => setHoverNever(true)}
            onMouseLeave={() => setHoverNever(false)}
            onClick={onClose}
          >
            Never
          </button>
        </div>
        {verificationOpen && (
          <PhoneNumberVerificationModal
            phoneNumber={phoneNumber}
            isOpen={verificationOpen}
            onClose={handleModalClose}
            onVerify={handleVerify}
            errorMessage={errorMessage} // Pass the errorMessage to the modal
          />
        )}
      </div>
    </div>
  );
}

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    background: "linear-gradient(to bottom, #24154A, #9D62BB)",
    borderRadius: "1.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    maxWidth: "400px",
    width: "90%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    margin: 0,
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  closeButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "white",
  },
  body: {
    marginTop: "20px",
    color: "white",
    textAlign: "left",
  },
  submitButton: {
    marginTop: "20px",
    width: "calc(100%)",
    padding: "10px",
    borderRadius: "1.0rem",
    backgroundColor: "#f472b6",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "1rem",
    color: "black",
    backgroundColor: "white",
    border: "1px solid #ccc",
  },
  prefix: {
    marginRight: '10px',
    color: 'white',
    fontSize: '16px',
  },
  maybeButton: {
    marginTop: "10px",
    width: "calc(100%)",
    padding: "10px",
    borderRadius: "1.0rem",
    backgroundColor: "transparent",
    color: "white",
    border: "1px solid #E50AA7",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
  neverButton: {
    marginTop: "10px",
    width: "calc(100%)",
    padding: "10px",
    borderRadius: "1.0rem",
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "background-color 0.3s ease",
  },
};

export default GetPhoneNumberModal;
