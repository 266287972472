import { fetchEventSource } from "@microsoft/fetch-event-source";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const messageService = {
  // Function to send a message to a specific chat
  sendMessage: async (messageText, onMessageReceived) => {
    const messageData = { content: messageText };
    await fetchEventSource(`${API_BASE_URL}/chat/messages`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(messageData),
      onmessage(event) {
        const data = JSON.parse(event.data);
        onMessageReceived(data);
      },
      onerror(err) {
        console.error("Stream encountered an error: ", err);
      },
    });
  },

  // Function to fetch messages for a specific chat
  fetchMessages: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/chat/messages`, {
        method: "GET",
        credentials: "include", // Important for cookies
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch messages");
      }
      return data; // Assuming this is an array of message objects
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  },

  // More functions related to messages can be added here
};

export default messageService;
