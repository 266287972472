// SignOutModal.jsx
import React from 'react';

const SignOutModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="rounded-lg shadow-xl w-full max-w-sm mx-4 p-8 bg-gradient-to-br from-purple-700 via-purple-800 to-purple-900" style={{ backgroundImage: `linear-gradient(to bottom, #24154A, #9D62BB)` }}>
        <h2 className="text-xl font-bold mb-4 text-left text-white">Sign out</h2>
        <p className="text-sm mb-4 text-left text-white">
          Are you sure you want to sign out?
          <br />
          <br/>
          Your conversation with R.H.E.A will not be personalized after you sign out.
          <br/>
          <br/>
        </p>
        <div className="flex justify-center space-x-4 mt-6">
          <button onClick={onClose} className="px-6 py-2 rounded text-white hover:bg-[#4C5070] transition duration-300 ease-in-out">Cancel</button>
          <button onClick={onConfirm} className="px-6 py-2 bg-[#EA0070] rounded text-white hover:bg-[#F472B6] transition duration-300 ease-in-out">Sign out</button>
        </div>
      </div>
    </div>
  );
};

export default SignOutModal;
